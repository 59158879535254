import * as Sentry from '@sentry/react';
import { FilterSetup } from 'types/filter.type';
import EditorData from 'components/editor-data/EditorData';
import { Brick, AddBrickPayload } from 'components/bricks/types/brick.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import PublishHelpers from '../helpers/publish.helpers';
import { BricksObject, Filters } from '../types/bricksComponentStore.type';
import { GetFilterSetupParams } from '../types/filter.type';
import { PAGE_LIMIT } from '../constants';
import BricksRequest from '../data';

export default class BrickDataService {
    static updateBricks = async (data: Brick[]): Promise<Brick[] | undefined> => {
        try {
            const response = await BricksRequest.patch(`bricks`, data);
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to save brick');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static saveBricks = async (data: AddBrickPayload[]): Promise<Brick[] | undefined> => {
        try {
            const response = await BricksRequest.post('bricks', data);
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to save brick');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static getBricksOverview = async (): Promise<Brick[] | undefined> => {
        try {
            const response = await BricksRequest.get('bricks');
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to get the data');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static loadMoreChildrenOfBrick = async (brickId: string, offset: number): Promise<BricksObject | undefined> => {
        try {
            const response = await BricksRequest.post(
                `bricks/${brickId}/children`,
                {},
                {
                    params: { offset: offset, pageLimit: PAGE_LIMIT }
                }
            );
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to get the data');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static getBricksOverviewAsObject = async (filters?: Filters, offset = 0): Promise<BricksObject | undefined> => {
        try {
            const response = await BricksRequest.post(
                'bricks/filters',
                { filters },
                {
                    params: { asObject: true, pageLimit: PAGE_LIMIT, offset }
                }
            );
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to get the data');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static getBrick = async (id: string): Promise<Brick[] | undefined> => {
        try {
            const response = await BricksRequest.get(`bricks/${id}`);
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to get the data');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static removeBrick = async (brickId: string | number): Promise<Brick | undefined> => {
        try {
            const response = await BricksRequest.delete('bricks/' + brickId);

            const removedBrick: Brick = response?.data;

            // Brick doens't exist
            if (!removedBrick || !removedBrick.id) return;

            // Remove the brick from the publish execution panel
            PublishHelpers.updateExecutionPanelBricks([removedBrick.id], 'remove');

            // Return the brick that is removed
            return removedBrick;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to remove brick');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    /**
     * Remove multiple bricks from the campaign based on the provided brick ids.
     */
    static removeMultipleBricks = async (brickIds: string[]): Promise<Brick[] | undefined> => {
        try {
            const response = await BricksRequest.delete('bricks', {
                data: { brickIds }
            });

            return response?.data;
        } catch (error: unknown) {
            Sentry.captureException(error);

            if (error instanceof Error) SnackbarUtils.error('Failed to delete bricks.');
        }
    };

    /**
     * Get publishing bricks
     * @returns bricks in the campaign which are curently in publish
     */
    static getPublishingBricks = async (): Promise<Brick[] | undefined> => {
        try {
            const response = await BricksRequest.get('bricks/publishing');

            if (!response) return;

            return response.data;
        } catch (error: unknown) {
            if (error instanceof Error) {
                SnackbarUtils.error('Failed getting publishing bricks');
            }
        }
    };

    /**
     * Duplicate bricks
     * @returns duplicated bricks
     */
    static duplicateBricks = async (bricks: Brick[], campaignId?: string): Promise<Brick[] | undefined> => {
        try {
            const currentCampaignId = EditorData.getId();

            const response = await BricksRequest.post(
                'bricks/duplicate',
                {
                    toCampaignId: campaignId || currentCampaignId,
                    brickIds: bricks.map((brick) => brick.id)
                },
                {
                    params: { campaignId }
                }
            );
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);

            if (error instanceof Error) SnackbarUtils.error('Failed to duplicate bricks.');
        }
    };

    /*
     * Generate feed output
     */
    static generateFeedOutput = async (brickId: string, datasetId: string): Promise<any> => {
        try {
            const response = await BricksRequest.post('bricks/feed/output', undefined, {
                params: { feedBrickId: brickId, datasetId }
            });
            return response?.data;
        } catch (error: unknown) {
            Sentry.captureException(error);

            if (error instanceof Error) SnackbarUtils.error('Failed generate output for feed brick.');
        }
    };

    /**
     * Cancel feed outpu
     */
    static cancelFeedOutput = async (feedBrickId: string, datasetId: string): Promise<any> => {
        try {
            const response = await BricksRequest.patch('bricks/feed/output', undefined, {
                params: { feedBrickId, datasetId }
            });
            return response?.data;
        } catch (error: unknown) {
            Sentry.captureException(error);

            if (error instanceof Error) SnackbarUtils.error('Failed generate output for feed brick.');
        }
    };

    /**
     * Removes all generated output bricks of a feed brick.
     */
    static removeOutputBricks = async (brickId: string): Promise<boolean> => {
        try {
            await BricksRequest.delete('bricks/output/' + brickId);
            return true;
        } catch (error: unknown) {
            Sentry.captureException(error);
            return false;
        }
    };

    /**
     * Gets filter setup for the generic filter
     * @param data Filter options mapping
     * @returns filter setup
     */
    static getFilterSetup = async (data: GetFilterSetupParams): Promise<FilterSetup | undefined> => {
        try {
            const response = await BricksRequest.post('bricks/filterSetup', data);

            return response.data || [];
        } catch (error: unknown) {
            Sentry.captureException(error);

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };
}

import { Brick } from '../types/brick.type';
import { PlacementsSection } from '../types/placement.type';
import BrickHelpers from './brick.helpers';

class AdPlacementsHelpers {
    /**
     * Check if there are more than one placement section or if any placement has more than one child
     */
    static hasMoreThanOnePlacement = (brick: Brick): boolean => {
        const placementsSections: PlacementsSection[] = BrickHelpers.getBrickData(brick?.subType, 'placements') ?? [];
        // Show placements if there are more than one placement section or if any placement has more than one child
        return placementsSections.some((placement) => placement.children.length > 1) || placementsSections.length > 1;
    };
}

export default AdPlacementsHelpers;

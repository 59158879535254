import ComponentStoreHelpers from 'components/data/ComponentStore';
import { CreativeViewType } from 'components/creatives-v2/components/creative-overview/types/creative-overview.type';
import { Brick, BrickSetup, BrickSetupTab } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import { UnifiedTab } from '../types/bricksComponentStore.type';
import ValidateHelpers from './validate.helpers';

interface BrickSlidePanelOptions {
    showComments?: boolean;
    activeTabKey?: BrickSetupTab['key'];
    view?: CreativeViewType;
    singleViewKey?: string;
    showSlidePanel?: boolean;
}

// Get the initial tab for the selected brick
const getInitialActiveTab = (brick: Brick, tabKey?: BrickSetupTab['key']): BrickSetupTab | undefined => {
    const brickSetup: BrickSetup = BrickHelpers.getBrickData(brick.subType, 'setup');

    // If a specific tab key is provided, return the corresponding tab
    if (tabKey) {
        return brickSetup.tabs.find((tab) => tab.key === tabKey);
    }

    // First check if the brick has a dynamic tab (so a tab based on some function checks)
    if (brickSetup.dynamicTab) {
        const dynamicTab = brickSetup.dynamicTab(brick);
        if (dynamicTab) {
            const foundTab = brickSetup.tabs.find((tab) => tab.key === dynamicTab);
            if (foundTab) return foundTab;
        }
    }

    // Return the default tab if specified, otherwise return the first tab
    return brickSetup.tabs.find((tab) => tab.key === brickSetup.defaultTab) || brickSetup.tabs[0];
};

const defaultOptions: BrickSlidePanelOptions = {
    showComments: false,
    showSlidePanel: true
};

const openSlidePanel = (brick: Brick, options: BrickSlidePanelOptions = defaultOptions): void => {
    // Get the default tab for the selected brick
    const { showComments, activeTabKey, view, singleViewKey, showSlidePanel = true } = options;
    const initialActiveTab = getInitialActiveTab(brick, activeTabKey);

    ComponentStoreHelpers.setMultiModels('Bricks', [
        ['slidePanelOpen', showSlidePanel],
        ['slidePanelCommentsOpen', showComments],
        ['activeItemId', brick.id],
        ['activeTab', initialActiveTab],
        ['creative.view', view],
        ['creative.singleViewKey', singleViewKey],
        ['checkedBricks', undefined] // Clear up the checked bricks to prevent conflicts with the multi-edit features
    ]);
};

const openSlidePanelSettings = (): void => {
    ComponentStoreHelpers.setModel('Bricks', 'slidePanelOpen', true);
    ComponentStoreHelpers.setModel('Bricks', 'slidePanelSettingsOpen', true);
};

const closeSlidePanelSettings = (): void => {
    ComponentStoreHelpers.setMultiModels('Bricks', [
        ['slidePanelOpen', false],
        ['slidePanelSettingsOpen', false]
    ]);
};

const closeSlidePanel = (): void => {
    ComponentStoreHelpers.setMultiModels('Bricks', [
        ['slidePanelOpen', false],
        ['slidePanelSettingsOpen', false]
    ]);

    // Timeout to make sure the slide panel closes quickly
    setTimeout(() => {
        const activeItemId = ComponentStoreHelpers.get('Bricks')?.activeItemId;

        ComponentStoreHelpers.removeItem('Bricks', 'activeItemId');
        ComponentStoreHelpers.removeItem('Bricks', 'activeTab');
        ComponentStoreHelpers.remove('CreativeEditorV2');
        ComponentStoreHelpers.remove('CreativeOverview');

        // TODO is this needed (the validation part)?
        const newBrick = BrickHelpers.getBrickById(activeItemId);
        if (newBrick) ValidateHelpers.validate(newBrick);
    }, 500);
};

const setActiveItem = (brickId?: string, activeTab?: UnifiedTab): void => {
    const brick = BrickHelpers.getBrickById(brickId);

    if (!brick) return;

    const brickSetup: BrickSetup = BrickHelpers.getBrickData(brick.subType, 'setup');
    const hasTab = brickSetup.tabs.find((tab) => tab.key === activeTab?.key);
    const intialActiveTab = getInitialActiveTab(brick);

    ComponentStoreHelpers.setMultiModels('Bricks', [
        ['activeItemId', brick.id],
        // if the tab that is currently selected exists in the brick that the user wants to open use that one otherwise use the default tab of the brick
        ['activeTab', hasTab ? hasTab : intialActiveTab]
    ]);
};

export { openSlidePanel, closeSlidePanel, openSlidePanelSettings, closeSlidePanelSettings, setActiveItem, getInitialActiveTab };

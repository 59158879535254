import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { Brick } from 'components/bricks/types/brick.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import User from 'components/data/User';
import { AssetRestrictions } from '../types/preset';
import BricksRequest from '../data';

export interface PublishResponse {
    publishId: string;
    jobId: string;
}

export interface ValidateAssetResponse {
    valid: boolean;
    errors: AssetRestrictions[];
    warnings: AssetRestrictions[];
}

export default class PublishService {
    static publishBricks = async (bricksToPublish: Brick[]): Promise<PublishResponse | undefined> => {
        const publishId = uuidv4();

        try {
            const response = await BricksRequest.post('bricks/output/publish', bricksToPublish, {
                params: { publishId }
            });

            if (response?.data?.jobId) {
                const jobId = response?.data?.jobId;

                return {
                    jobId,
                    publishId
                };
            }
        } catch (error: unknown) {
            Sentry.captureException(error);
            if (error instanceof Error) {
                SnackbarUtils.error('Publish request failed');
            }
        }
    };

    /**
     * Downloads bricks
     * @param bricks
     * @returns publish job id
     */
    static downloadBricks = async (bricks: Brick[]): Promise<PublishResponse | undefined> => {
        const publishId = uuidv4();

        try {
            const response = await BricksRequest.post<{ jobId: string }>('bricks/output/download', { bricks, publishId });

            if (response?.data?.jobId) {
                const jobId = response?.data?.jobId;

                return {
                    jobId,
                    publishId
                };
            }
        } catch (error: unknown) {
            Sentry.captureException(error);
            if (error instanceof Error) {
                SnackbarUtils.error('Publish request failed');
            }
        }
    };

    /**
     * Downloads brick products
     * @param bricks
     */
    static downloadBrickProducts = async (brickIds: string[], publishId?: string): Promise<string | undefined> => {
        try {
            const response = await BricksRequest.post('bricks/output/download/products', { brickIds, publishId });
            return response?.data;
        } catch (error: unknown) {
            if (error instanceof Error) {
                SnackbarUtils.error('Publish request failed');
            }
        }
    };

    /**
     * Publishes feed
     * @param brick feed
     * @returns job status
     */
    // static publishFeed = async (brick: Brick): Promise<PublishResponse | undefined> => {
    //     PublishHelpers.handleBrickPublishStatusUpdate(brick);

    //     try {
    //         const response = await BricksRequest.post('bricks/output/publish/feed', {
    //             params: { brickId: brick.id }
    //         });

    //         if (response?.data?.jobId) {
    //             PublishHelpers.handleBrickPublishStatusUpdate(brick);

    //             return {
    //                 status: 'success',
    //                 // jobId: response.data.jobId,
    //                 message: 'Publish request successful.'
    //             };
    //         }

    //         PublishHelpers.handleBrickPublishStatusUpdate(brick);

    //         return {
    //             status: 'error',
    //             message: 'Publish request successful, but no jobId in the response.'
    //         };
    //     } catch (error: unknown) {
    //         Sentry.captureException(error);
    //         PublishHelpers.handleBrickPublishStatusUpdate(brick);

    //         if (error instanceof Error) {
    //             SnackbarUtils.error('Publish request failed');
    //         }
    //     }
    // };

    static cancelPublishJob = async (publishId: string): Promise<boolean> => {
        try {
            const response = await BricksRequest.post(`bricks/output/${publishId}/cancel`, undefined);

            if (response?.status === 200) {
                SnackbarUtils.info(Translation.get('output.cancel.successfulCancel', 'bricks'));
                return true;
            }
        } catch (error: unknown) {
            Sentry.captureException(error);
            if (error instanceof Error) {
                SnackbarUtils.error(Translation.get('output.cancel.failedCancel', 'bricks'));
            }
        }

        return false;
    };

    /**
     * Gets the detailed messages of a publish job error, warning or report.
     */
    static getPublishJobsDetailedMessages = async (jobId: string, messageType: string, messageIdentifier: string): Promise<string[]> => {
        try {
            const response = await axios.get(`${process.env.PUBLISH_ENGINE_V3}/jobs/${jobId}/messages/${messageType}/${messageIdentifier}`, {
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });
            return response.data ?? [];
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to get publish job messages');
            return [];
        }
    };
}

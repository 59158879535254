import { Message } from 'services/messaging-client/Message';
import { BrickPublishJob } from './brick-publish.type';

// This is the bricks publish status and not the publish status of a publish job
// The status can be different based on the statuses of multiple publish jobs
export type BrickPublishStatus = 'readyToPublish' | 'success' | 'publishing' | 'error' | 'info' | 'blocking' | 'canceled';

export interface BrickPublishResultsMessage extends Message {
    /**
     * The type of the message, this is used to determine what the message contents will be. Please use the following naming scheme when creating messages:
     * internal/[APPLICATION NAME]/[COMPONENT NAME]/[MESSAGE DESCRIPTOR]
     */
    path: 'internal/bricks/publish/results';
    /**
     * The rights a user needs to have to be able to receive this message
     */
    rights: string[];

    /**
     * To which topic this message belongs, this message will not be delivered to users not subscribed to this topic.
     */
    topic?: string;

    content: {
        /**
         * The account id of the account this job belongs to
         */
        accountId: number;
        /**
         * The user id of the user this job belongs to
         */
        userId: number;
        /**
         * The campaign id of the campaign this job belongs to
         */
        campaignId?: number;
        /**
         * The campaign id of the campaign this job belongs to
         */
        publishId: string;

        /**
         * Brick id
         */
        brickId: string;

        jobId: string;

        data: BrickPublishJob;

        outputAction: OutputAction;
    };
}

export interface BrickPublishStartMessage extends Message {
    path: 'internal/bricks/publish/start';
    /**
     * The rights a user needs to have to be able to receive this message
     */
    rights: string[];

    /**
     * To which topic this message belongs, this message will not be delivered to users not subscribed to this topic.
     */
    topic?: string;
    content: {
        /**
         * The account id of the account this job belongs to
         */
        accountId: number;
        /**
         * The user id of the user this job belongs to
         */
        userId: number;
        /**
         * The campaign id of the campaign this job belongs to
         */
        campaignId?: number;
        /**
         * The campaign id of the campaign this job belongs to
         */
        publishId?: string;

        /**
         * Job id
         */
        jobId?: string;

        brickIds: string[];

        outputAction: OutputAction;
    };
}

export interface BrickPublishCancelMessage extends Message {
    path: 'internal/bricks/output/publish/cancel';
    content: {
        /**
         * The account id of the account this job belongs to
         */
        accountId: number;
        /**
         * The user id of the user this job belongs to
         */
        userId: number;
        /**
         * The campaign id of the campaign this job belongs to
         */
        campaignId?: number;
        /**
         * The campaign id of the campaign this job belongs to
         */
        publishId: string;
        /**
         * The brick ids which were canceled
         */
        brickIds: string[];
    };
}

export interface BrickPublishFinishMessage extends Message {
    path: 'internal/bricks/output/publish/finish';
    content: {
        /**
         * The account id of the account this job belongs to
         */
        accountId: number;
        /**
         * The user id of the user this job belongs to
         */
        userId: number;
        /**
         * The campaign id of the campaign this job belongs to
         */
        campaignId?: number;
        /**
         * The campaign id of the campaign this job belongs to
         */
        publishId?: string;
        /**
         * url to download the output
         */
        url?: string;
    };
}

export enum JobStatus {
    WAITING = 0,
    WORKING = 1,
    FINISHED = 2,
    FAILED = 3,
    CANCELED = 4,
    WARNING = 5
}

export interface JobStatusMessages {
    /**
     * Errors for the job
     */
    errors: {
        [key: string]: JobMessageIdentifier;
    };

    /**
     * Warnings for the job
     */
    warnings: {
        [key: string]: JobMessageIdentifier;
    };

    /**
     * Reports for the job
     */
    reports: {
        [key: string]: JobMessageIdentifier;
    };
}

export interface JobMessageIdentifier {
    count: number;
    description: string | null;
    latestEntry: string | null;
}

export type OutputAction = 'download' | 'publish';

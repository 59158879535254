import set from 'lodash/set';
import Request from 'components/data/Request';
import Resources from 'components/data/Resources/components';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';

const RESOURCE_BRICKS_STATE = 'bricks_state';
const RESOURCE_CUSTOM_PRESETS = 'custom_presets';

type AvailableResources = typeof RESOURCE_BRICKS_STATE | typeof RESOURCE_CUSTOM_PRESETS;

interface ResourceOverviewResponse {
    data: ResourceOverview[];
}
interface ResourceOverview {
    id: string;
    resource: string;
}

class BricksResourceHelpers {
    /**
     * Gets campaign brick state
     * @param campaignId
     * @returns campaign brick state
     */
    static getCampaignBricksState(campaignId: string): any {
        const bricksState = Resources.get(RESOURCE_BRICKS_STATE);

        if (!bricksState) return;

        return bricksState[`bricks_${campaignId}_state`];
    }

    static setBricksState(campaignId: string, path: string, value: unknown): void {
        const valuePath = `bricks_${campaignId}_state.${path}`;
        BricksResourceHelpers.updateResource(RESOURCE_BRICKS_STATE, valuePath, value);
    }

    static setCustomPresets(path: string, value: unknown): void {
        BricksResourceHelpers.updateResource(RESOURCE_CUSTOM_PRESETS, path, value);
    }

    /**
     * Update the value of a resource
     * @param resourceName - The name of the resource (e.g., RESOURCE_BRICKS_STATE, RESOURCE_CUSTOM_PRESETS).
     * @param path - Path inside the resource to set the value at.
     * @param value - The value to set within the resource.
     */
    private static async updateResource(resourceName: AvailableResources, path: string, value: unknown): Promise<void> {
        const resourceId = await BricksResourceHelpers.fetchResourceId(resourceName);
        const resourceValue = Resources.get(resourceName) || {};

        // Set the value at the given path
        set(resourceValue, path, value);

        try {
            BricksResourceHelpers.saveResource(resourceName, resourceValue, resourceId);
        } catch (error) {
            SnackbarUtils.error(`Error updating resource`);
        }
    }

    /**
     * Saves value in the specified resource and if the id doesn't exist, creates a new resource.
     * @param resourceName - The name of the resource to save the value in.
     * @param value - The new value to save.
     * @param id - The optional resource id.
     */
    private static saveResource(resourceName: AvailableResources, value = {}, id?: string): void {
        try {
            Request.post('resources/save', {
                id,
                data: { id, resource: resourceName, data: JSON.stringify(value) }
            });
            Resources.set(resourceName, value);
        } catch (error) {
            SnackbarUtils.error(`Error saving resource`);
        }
    }

    /**
     * Fetches the resource ID for a given resource name.
     * @param resourceName The name of the resource to find.
     * @returns {Promise<string | undefined>} The resource ID if found, otherwise undefined.
     */
    private static async fetchResourceId(resourceName: AvailableResources): Promise<string | undefined> {
        try {
            const response: ResourceOverviewResponse = await Request.post('resources/overview');
            if (!response || !response.data) return;
            const resource = response.data.find((res) => res.resource === resourceName);
            return resource?.id;
        } catch (error) {
            SnackbarUtils.error(`Error fetching resource ID`);
        }
    }
}

export default BricksResourceHelpers;

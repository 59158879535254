import { Message } from 'services/messaging-client/Message';
import { MessageClient } from 'services/messaging-client/MessageClient.service';
import merge from 'lodash/merge';
import ComponentStore, { ComponentStoreHelpers } from 'components/data/ComponentStore';

import cloneDeep from 'helpers/cloneDeep';
import LWFiles from 'components/data/Files';
import User from 'components/data/User';
import { BrickPublishCancelMessage, BrickPublishFinishMessage, BrickPublishResultsMessage, BrickPublishStartMessage, JobStatus } from '../types/publish.type';

import { BricksComponentStore, CSBrickPublishJob, PublishJobs } from '../types/bricksComponentStore.type';
import { BrickPublishJob } from '../types/brick-publish.type';

const PATH_PUBLISH_START = 'internal/bricks/output/publish/start';
const PATH_PUBLISH_RESULTS = 'internal/bricks/output/publish/result';
const PATH_PUBLISH_FINISH = 'internal/bricks/output/publish/finished';
const PATH_CANCEL_OUTPUT = 'internal/bricks/output/cancel';

export default class PublishMessagesService {
    /**
     * Subscribes to publish topic
     * @param onMessage function to handle getting the messages
     * @return subscription id
     */
    static subscribe = (publishId: string): string => {
        const messageClient = MessageClient.getInstance();
        return messageClient.subscribeToTopic(`internal/bricks/output/publish/${publishId}`, (message) => {
            this.onMessage(message);
        });
    };

    /**
     * Unsubscibe from a topic
     * @param subscription id
     */
    static unsubscribe = (publishId: string, subscription: string): void => {
        const messageClient = MessageClient.getInstance();
        messageClient.unsubscribeFromTopic(`internal/bricks/output/publish/${publishId}`, subscription);
    };

    /**
     * Handles message
     * @param message
     */
    static onMessage = (message: Message): void => {
        switch (message.path) {
            case PATH_PUBLISH_START:
                this.handleStartPublishMessage(message as BrickPublishStartMessage);
                break;
            case PATH_PUBLISH_RESULTS:
                this.handleSaveResultsMessage(message as BrickPublishResultsMessage);
                break;
            case PATH_PUBLISH_FINISH:
                this.handlePublishFinishMessage(message as BrickPublishFinishMessage);
                break;
            case PATH_CANCEL_OUTPUT:
                this.handleCancelOutput(message as BrickPublishCancelMessage);
        }
    };

    static handleStartPublishMessage = (message: BrickPublishStartMessage): void => {
        const { publishId, brickIds, jobId, outputAction } = message.content;
        if (!publishId || !jobId) return;

        let publishJob: CSBrickPublishJob = { publishId, bricks: {} };

        const bricks: { [key: string]: BrickPublishJob } = {};

        brickIds.forEach((brickId: string) => {
            bricks[brickId] = {
                status: 1,
                jobToken: jobId,
                publishId,
                totalTasks: 0,
                finishedTasks: 0,
                failedTasks: 0,
                createdAt: new Date().toISOString(),
                createdBy: Number(User.get('id')),
                outputAction
            };
        });

        publishJob = { ...publishJob, bricks };

        this.modifyPublishJob(publishJob);
    };

    static handleSaveResultsMessage = (message: BrickPublishResultsMessage): void => {
        const { publishId, data, brickId } = message.content;

        const publishJob: CSBrickPublishJob = {
            publishId,
            bricks: {
                [brickId]: data
            }
        };

        this.modifyPublishJob(publishJob);
    };

    static modifyPublishJob = (newPublishJob: CSBrickPublishJob): void => {
        const publishJobs: PublishJobs | undefined = cloneDeep(ComponentStoreHelpers.getItem('Bricks', `publish`));

        if (!publishJobs) return;

        let publishJob: CSBrickPublishJob = publishJobs?.[newPublishJob.publishId];

        if (publishJob) publishJob = merge(publishJob, newPublishJob);
        else publishJob = { ...newPublishJob };

        publishJobs[newPublishJob.publishId] = publishJob;

        const outputAction = newPublishJob.bricks[Object.keys(newPublishJob.bricks)[0]].outputAction;

        if (outputAction === 'download' && publishJob.bricks) {
            // check if all bricks are finished or failed
            // if so then add publishId to download list in redux
            const bricks = Object.values(publishJob.bricks);
            const allFinished = bricks.every((brick) => brick.status === JobStatus.FINISHED || brick.status === JobStatus.FAILED);
            if (allFinished) {
                ComponentStore.setMultiModels('Bricks', [[`download.${newPublishJob.publishId}`, false]]);
            }
        }

        ComponentStore.setModel('Bricks', `publish`, publishJobs);
    };

    static handlePublishFinishMessage = (message: BrickPublishFinishMessage): void => {
        const { topic } = message;
        const { url } = message.content;

        if (!url) return;

        const publishId = topic?.split('/').pop();

        const name = url.split('/').pop();
        if (!name) return;
        if (!publishId) return;

        // last message was sent so we are unsubscribing from the topic
        const backendSubscriptions = ComponentStore.get('Bricks')?.backendSubscriptions || [];

        const subscription = backendSubscriptions.find((sub) => sub.publishId === publishId);
        if (subscription) {
            this.unsubscribe(publishId, subscription.subscriptionId);
        }

        // remove backend subscription
        const newBackendSubscriptions = backendSubscriptions.filter((sub) => sub.publishId !== publishId);
        ComponentStore.setModel('Bricks', 'backendSubscriptions', newBackendSubscriptions);

        const downloadStatus = ComponentStore.getItem('Bricks', `download.${publishId}`);

        // update the publish execution panel items status with the download url
        const publishlist: BricksComponentStore['publish'] | undefined = cloneDeep(ComponentStoreHelpers.getItem('Bricks', `publish`));
        if (!publishlist) return;
        const publishJob = publishlist[publishId];
        if (!publishJob) return;

        publishJob.downloadUrl = url;

        ComponentStore.setModel('Bricks', `publish`, publishlist);

        // TODO: Remove on fix websocket receiver issue receiving multiple messages
        // if the download state is set for the publish id we are not redownloading the file again
        if (publishId && downloadStatus) return;

        ComponentStore.setMultiModels('Bricks', [[`download.${publishId}`, true]]);

        LWFiles.downloadFileFromUrl(url, name, () => {
            ComponentStore.removeItem('Bricks', `download.${publishId}`);
        });
    };

    /**
     * Sets the job status to canceled
     * @param message
     */
    static handleCancelOutput = (message: BrickPublishCancelMessage): void => {
        const { publishId, brickIds } = message.content;
        const publishJobs: PublishJobs | undefined = cloneDeep(ComponentStoreHelpers.getItem('Bricks', `publish`));

        if (!publishJobs) return;

        const publishJob: CSBrickPublishJob = publishJobs?.[publishId];
        if (!publishJob) return;

        // Loops through the bricks in the publish job and sets the status to canceled
        for (const brickId of brickIds) {
            if (publishJob.bricks[brickId].finishedTasks === publishJob.bricks[brickId].totalTasks) continue;

            publishJob.bricks[brickId].status = JobStatus.CANCELED;
        }

        publishJobs[publishId] = publishJob;

        ComponentStore.setModel('Bricks', `publish`, publishJobs);
    };
}

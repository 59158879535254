import allBricks from 'components/bricks/components/bricks';
import { Brick, BrickChannel, BrickOutputAction, BrickSetup, BrickSocialPlatform, BrickSubType } from '../types/brick.type';
import BrickHelpers from './brick.helpers';

type BrickTitleMapping = { [key in BrickSubType]: string };

type BackendRightsMapping = Record<BrickSubType, { subType: BrickSubType; channel: BrickChannel; platform?: BrickSocialPlatform }>;

/** Helper class for setup bricks. */
class BrickSetupHelpers {
    /**
     * Get the title of the setup brick.
     * @param brick The brick to get the setup title for.
     * @returns The title of the setup brick.
     */
    static getSetupBrickTitle(brick: Brick): string {
        const setup: BrickSetup = BrickHelpers.getBrickData(brick.subType, 'setup');
        return setup?.title;
    }

    /**
     * Gets the output action from the brick setup based on the provided brick id.
     */
    static getOutputAction = (brickId: string): BrickOutputAction | undefined => {
        const brick = BrickHelpers.getBrickById(brickId);
        const setup = BrickHelpers.getBrickData<BrickSetup | undefined>(brick?.subType, 'setup');
        return setup?.outputAction;
    };

    /**
     * Get the title for each brick in the setup
     * @returns The title of the setup brick.
     */
    static getBrickTitleMapping = (): BrickTitleMapping => {
        const allAvailableBrickSetups = Object.values(allBricks).map((brick) => brick.setup);

        const brickTitleMapping = Object.fromEntries(allAvailableBrickSetups.map((brick) => [brick.subType, brick.title])) as BrickTitleMapping;

        return brickTitleMapping;
    };

    // This is a function to generate an object that has every brick subType with the channel and platform
    // We use this object in the backend to check if the user has the rights to the brick, because the channel/platform are not in the brick data itself
    static generateMappingForBackendRights = (): BackendRightsMapping => {
        const allAvailableBrickSetups = Object.values(allBricks).map((brick) => brick.setup);
        const setupForRights = Object.fromEntries(
            allAvailableBrickSetups.map((brick) => [
                brick.subType,
                {
                    subType: brick.subType,
                    channel: brick.channel,
                    platform: brick.platform
                }
            ])
        ) as BackendRightsMapping;

        return setupForRights;
    };
}

export default BrickSetupHelpers;

import ComponentStoreHelpers from 'components/data/ComponentStore';
import { Brick } from '../types/brick.type';
import PublishService from '../services/publish.service';
import { BricksComponentStore } from '../types/bricksComponentStore.type';
import PublishHelpers from './publish.helpers';
import { BrickPublishJob } from '../types/brick-publish.type';
import PublishMessagesService from '../services/publish-messages.service';
class DownloadHelpers {
    /**
     * Downloads the bricks
     * @param bricks a list of bricks that we want to download
     * @returns a list of publish jobs per brick id
     */
    static async downloadBricks(bricks: Brick[]): Promise<(BrickPublishJob & { brickId: string })[] | undefined> {
        const result = await PublishService.downloadBricks(bricks);

        if (!result) return;

        const { jobId, publishId } = result;

        const publishJob = PublishHelpers.handleAddBricksPublishJob(bricks, jobId, publishId, 'default', 'download');
        PublishHelpers.handleBrickPublishStatusUpdate(bricks, publishId);

        const publishjobs = bricks.map((brick) => {
            return {
                brickId: brick.id,
                ...publishJob
            };
        });

        const subscriptionId = PublishMessagesService.subscribe(publishId);

        const backendSubscriptions = ComponentStoreHelpers.get('Bricks')?.backendSubscriptions || [];

        ComponentStoreHelpers.setModel('Bricks', 'backendSubscriptions', [
            ...backendSubscriptions,
            {
                publishId,
                subscriptionId
            }
        ]);

        return publishjobs;
    }

    /**
     * Checks if the download job is fully finished
     * @param jobs list of publish jobs
     * @returns a boolean indicating if the download job is fully finished
     */
    static isPublishDownloading(jobs: BrickPublishJob[]): boolean {
        let isPublishDownloading = false;
        const type = jobs[0]?.outputAction; // This is needed in order to know if the current jobs are download or publish jobs

        if (type === 'download') {
            const brickComponentStore: BricksComponentStore | undefined = ComponentStoreHelpers.get('Bricks');
            const downloads = brickComponentStore?.download;

            if (downloads && Object.keys(downloads).length > 0) {
                isPublishDownloading = jobs.every((job) => downloads[job.publishId] === false || downloads[job.publishId] === true);
            } else isPublishDownloading = false;
        }

        return isPublishDownloading;
    }
}

export default DownloadHelpers;
